import './socials.scss'
import cv from '../../assets/cv1.pdf'

export default function Socials({ isPhone }) {
  if (isPhone) return null

  return (
    <div className='socials'>
      <a href='https://github.com/MoreiraP12' target= '_blank' title='Github'>
        <i class="fab fa-github"></i>
      </a>
      <a href='mailto: pedromoreirah3@gmail.com' title='Mail'>
        <i class="far fa-envelope"></i>
      </a>
      <a href='https://www.linkedin.com/in/pjfmoreira/' target= '_blank' title='LinkedIn'>
        <i class="fab fa-linkedin"></i>
      </a>
      <a href={cv} target= '_blank' title='Resume'>
        <i class="far fa-id-card"></i>
      </a>
    </div>
  )
}