import epita from './assets/epita.png'
import ens from './assets/ens.png'
import factonics from './assets/factonics.png'
import siemens from './assets/siemens.png'
import concordia from './assets/concordia.png'
import website from './assets/website.png'
import pm from './assets/predictionmodel.png'
import bazzle from './assets/bazzle.png'
import informado from './assets/informado.png'
import graph from './assets/stcpgraph.jpg'
import flappybird from './assets/flappybird.jpg'
import paddle88 from './assets/paddle88.png'


const experienceData = [
  {
    company: 'FCUP',
    logo: concordia,
    title: 'PhD Research Assistant',
    startDate: new Date(2019, 7, 15),
    endDate: new Date(2019, 10, 30),
    description: [
      <p>Assisted Dr. Ivo Dias with his research in analogues for Darunavir and its acopolation with other HIV related medicines.</p>,
      <p>Tags: <b>ChemDraw, MestReNova, MestRec</b></p>
    ],
    color: 'red',
    position: 'bottom'
  },
  {
    company: 'FCUP',
    logo: concordia,
    title: 'Masters in Physics Engineering',
    startDate: new Date(2019, 7, 15),
    endDate: new Date(2020, 8, 22),
    description: [
      <p>A course with a strong emphasis on Math, Physics and Programming.</p>,
      <p>Physics helped me to understand the world around me, and satisfy my curiosity. Despite interropting the course, studying physics developed my <u>critical thinking</u> and <u>problem-solving skills</u>.</p>,
      <p>Tags : <b> Python, Latex</b></p>
    ],
    color: 'blue',
    position: 'center'
  },
  {
    company: 'ShARE-UP',
    logo: ens,
    title: 'Consultant',
    startDate: new Date(2020, 1, 1),
    endDate: new Date(2022, 3, 31),
    description: [
      <p><u><a href='https://share-up.pt/' target="_blank">ShARE</a></u> is an innovative start-up at the crossroad of education and consulting. It runs a leadership programme to turn bright university students into Do Well Do Good leaders.</p>,
      <p>Tags: <b> Notion, Monday</b></p>
    ],
    color: 'green',
    position: 'bottom'
  },
  {
    company: 'FEUP',
    logo: epita,
    title: 'Bachelors in Informatics and Computer Engineering',
    startDate: new Date(2020, 9, 1),
    endDate: new Date(2023, 8, 1),
    description: [
      <p>The study of the structure, behaviour, and interactions of natural and engineered computational systems. I've learned databases, new languages, design patterns, microprocessors and so much more.</p>,
      <p>Tags: <b> Python, C, C++, MySQL, Java</b></p>
    ],
    color: 'blue',
    position: 'center'
  },
  {
    company: 'UPF (Barcelona) | Sapienza Università di Roma | UCL (London)',
    logo: epita,
    title: 'Masters in Artificial Intelligence',
    startDate: new Date(2023, 9, 1),
    endDate: new Date(2024, 4, 30),
    description: [
      <p>Studying in a highly reputable European program focusing on AI (acceptance rate: 2%)
      Comprehensive two-year program specializing in AI, covering Deep Learning, NLP, Computer Vision, and Reinforcement Learning.</p>
    ],
    color: 'red',
    position: 'center'
  },
  {
    company: 'Coverflex',
    logo: factonics,
    title: 'Internship',
    startDate: new Date(2020, 9, 1),
    endDate: new Date(2021, 2, 31),
    description: [
      <p><u><a href='https://www.coverflex.com/' target="_blank">Coverflex</a></u> is the number one portuguese fintech according to Forbes. In my time with them I was consulting on new markets.</p>,
    ],
    color: 'light-green',
    position: 'bottom'
  },
  {
    company: 'JuniFEUP',
    logo: siemens,
    title: 'Developer',
    startDate: new Date(2021, 8, 10),
    endDate: new Date(2022, 6, 31),
    description: [
      <p>I worked in both external projects (such as: <u>Padel88</u>) and internal projects that range from databases with simple UI to apps for events.</p>,
      <p>Tags: <b>PHP, React, React-Native, PostgreSQL, Firebase, Docker </b></p>
    ],
    color: 'purple',
    position: 'top'
  },
  {
    company: 'Bazzle Arch',
    logo: factonics,
    title: 'Full Stack Developer',
    startDate: new Date(2021, 3, 5),
    endDate: new Date(2021, 9, 31),
    description: [
      <p>An innovative start-up that democratizes architecture and designing.</p>,
      <p>I was responsible for the first iteration of Bazzle's website.</p>,
      <p>Tags: <b>HTML5, CSS3, BootStrap, JavaScript</b></p>
    ],
    color: 'light-green',
    position: 'bottom'
  },

  {
    company: 'Dá o clique',
    logo: factonics,
    title: 'Freelancer',
    startDate: new Date(2021, 10, 5),
    endDate: new Date(2022, 3, 31),
    description: [
      <p>Developed the website for Dá o clique. A platform to help individuals cope with porn addiction</p>,
      <p>Tags: <b>Wordpress</b></p>
    ],
    color: 'light-green',
    position: 'bottom'
  },
   {
    company: 'Kodly',
    logo: factonics,
    title: 'Summer Internship',
    startDate: new Date(2022, 3, 31),
    endDate: new Date(2022, 9, 30),
    description: [
      <p>Refatoring and adding functionalities to the current backend of "Throdle App"</p>,
      <p>Tags: <b>Kotlin, AWS, Micronaut</b></p>
    ],
    color: 'dark-green',
    position: 'bottom'
  },
  {
    company: 'Google Developers Students Club',
    logo: factonics,
    title: 'Lead',
    startDate: new Date(2022, 8, 31),
    endDate: new Date(2023, 8, 30),
    description: [],
    color: 'yellow',
    position: 'top'
  },
  {
    company: 'INESC TEC',
    logo: factonics,
    title: 'Researcher',
    startDate: new Date(2022, 11, 1),
    endDate: new Date(2023, 7, 30),
    description: [],
    color: 'cyan',
    position: 'bottom'
  },
]

const projectsData = [
  {
    id: 0,
    title: 'Flappy Bird',
    image: flappybird,
    link: '',
    description: (
      <p>A revival of the ultimate indie game "Flappy Bird". With the porpuse of display our skill in <b>Design Patterns</b>, <b>Code Smells</b> and the respective refactoring.</p>
    ),
    tags: [
      {
        text: 'Java',
        color: 'var(--red)',
        backgroundColor: 'var(--bg-red)',
      },
    ]
  },
  {
    id: 1,
    title: 'Bazzle',
    image: bazzle,
    description: (
      <div>
        <p><b>Bazzle Arch</b> is a platform that connects architects and designers with anyone who wants to build something - no matter how small.</p>
      </div>
    ),
    link: '',
    tags: [
      {
        text: 'HTML',
        color: 'var(--green)',
        backgroundColor: 'var(--bg-green)',
      },
      {
        text: 'CSS',
        color: 'var(--red)',
        backgroundColor: 'var(--bg-red)',
      },
    ]
  },
  {
    id: 2,
    title: '+Informado',
    image: informado,
    description: (
      <div>
        <p>Online forum with the purpose of <b>connecting informal caregivers</b>.A space for suggestions and tips.</p>
      </div>
    ),
    link: '',
    tags: [
       {
        text: 'React',
        color: 'var(--purple)',
        backgroundColor: 'var(--bg-purple)',
      },
    ]
  },
  {
    id: 3,
    title: 'Prediction Model',
    image: pm,
    description: (

      <p>We were provided the data of 28.624 individuals and we were asked to predict whether they would be amenable to make a charitable donation.</p>
    ),
    link: '',
    tags: [
      {
        text: 'Python',
        color: 'var(--cyan)',
        backgroundColor: 'var(--bg-cyan)',
      },
    ]
  },
  {
    id: 4,
    title: 'STCP Graphs & Transports',
    image: graph,
    description: (
      <p>
        Using data provided by STCP we modeled the bus network into multiple <b>graphs</b>. Search mechanisms used my own variation of <b>Bellman-Ford</b>, <b>Djikstra</b> and <b>BFS</b> algorithms .
      </p>
    ),
    link: '',
    tags: [
      {
        text: 'C++',
        color: 'var(--blue)',
        backgroundColor: 'var(--bg-blue)',
      },
    ]
  },
  {
    id: 5,
    title: 'Personal website',
    image: website,
    description: (
      <p>
        Website made in <b>functional React</b>. Features a <b>homepage 3D animation</b>.
      </p>
    ),
    link: '',
    tags: [
      {
        text: 'React',
        color: 'var(--purple)',
        backgroundColor: 'var(--bg-purple)',
      },
    ]
  },
  {
    id: 6,
    title: 'Padel88',
    image: paddle88,
    description: (
      <p>
        App to support various portuguese padel leagues. Using <b>React Native</b> and <b>Firebase</b> amongst other technologies
      </p>
    ),
    link: '',
    tags: [
      {
        text: 'React Native',
        color: 'var(--pink)',
        backgroundColor: 'var(--bg-pink)',
      },
    ]
  },
]

export { experienceData, projectsData }